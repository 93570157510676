$(function() {
	$('#offcanvas').mmenu({
		'navbar': {
			'add': false
		},
		'extensions': [
			"pagedim-black"
		]
	}, {
		// configuration
		offCanvas: {
			pageNodetype: '.page'
		},
	});
});